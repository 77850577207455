
<template>
  <div
    id="login"
    style="min-height: calc(100vh - 20px)"
    class="login-page"
    tag="section"
  >
    <b-row>
      <div class="login-box" no-body>
        <div class="login-wrapper">
           <img src="@/assets/images/venue_booking/logo.svg" id="logo_image" />
          <b-overlay :show="login_emailshow" rounded="sm">
            <b-form id="email_login_form" @submit.prevent="handleSubmitEmail">
              <b-form-group>
                <b-form-input
                  id="txt-password"
                  type="password"
                  placeholder="Enter Password"
                  class="mb-3"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="pehrs-1-live-feedback"
                  v-if="$v.form.password.$error"
                >
                  <div v-if="!$v.form.password.required">
                    Please enter your password
                  </div>
                  <div v-if="!$v.form.password.minLength">
                    Please enter valid password
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  id="txt-pwd"
                  type="password"
                  placeholder="Enter Confirm Password"
                  class="mb-3"
                  v-model="$v.form.cpassword.$model"
                  :state="validateState('cpassword')"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="txt-checkPassword"
                  v-if="$v.form.cpassword.$error"
                >
                  <div v-if="!$v.form.cpassword.sameAsPassword">
                    New Password and Confirm Password should match
                  </div>
                  <div v-if="!$v.form.cpassword.required">
                    Please enter your confirm password
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-alert :show="loginfail_a" variant="danger" class="bt-alert">
                <a class="alert-link d-flex align-items-center">
                  <b-button variant="danger" disabled>
                    <i class="mdi mdi-alert-circle"></i>
                  </b-button>
                  {{ login_fail }}
                </a>
              </b-alert>
              <b-form-group>
                <b-button type="submit" variant="primary" block class="mt-2">
                  UPDATE PASSWORD
                </b-button>
              </b-form-group>
            </b-form>
          </b-overlay>
        </div>
      </div>
    </b-row>
  </div>
</template>
    

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "BoxedLogin",
  mixins: [validationMixin],
  data: () => ({
    form: {
      password: "",
      cpassword: "",
    },

    checkbox: false,
    email_login_flag: 1,
    username_login_flag: 0,
    otp_flag: 0,
    flag: "",
    login_fail: "",
    otp_fail: "",
    loginfail_a: false,
    otpfail_a: false,
    login_emailshow: false,
    otp_show: false,
    token: "",
  }),
  computed: {},
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
      },
      cpassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    validateState(email) {
      const { $dirty, $error } = this.$v.form[email];
      return $dirty ? !$error : null;
    },

    handleSubmitEmail() {
      this.token = this.$route.params.token;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.login_emailshow = true;
        axios
          .post("/api/reset_password", {
            password: this.form.password,
            password_confirmation: this.form.cpassword,
            token: this.token,
          })
          .then((resp) => {
            if (resp.data.status) {
              this.$root.$refs.app.showToast("success", resp.data.message);
              this.login_emailshow = false;
              this.loginfail_a = false;
              this.flag = "";
              this.email_login_flag = 0;
              this.$router.push("/login");
            } else {
              this.login_emailshow = false;
              this.loginfail_a = true;
              this.login_fail = resp.data.message;
            }
          });
      }
    },
    checkLink() {
      this.token = this.$route.params.token;
      axios
        .post("/api/reset_password_check", { token: this.token })
        .then((resp) => {
          if (resp.data.status === false) 
          {
            this.$router.push("/error");
          }
          if (resp.data.token_expired === true)
          {
               this.$router.push("/expire_link");
          }
          
        });
    },
  },
  mounted() {
    this.checkLink();
  },
};
</script>
<style scoped>
.login-page {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#logo_image {
   width: 180px;
  /* margin-left: 90px; */
  /* height: 60px; */
  padding-bottom: 15px;
}
.login-wrapper {
  text-align: center;
}
.login-box {
  background: #fff;
  max-width: 400px;
  width: 100%;
  padding: 30px;
  margin: 8% auto;
}
@media screen and (max-width: 475px) {
  .login-box {
    max-width: 100%;
    margin: 0 30px;
  }
}
</style>